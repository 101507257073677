.menu {
	width: 320px;
	height: 100vh;
	background: white;
	float: left;
	font-family: 'Inter', sans-serif;
}

.menu .header {
	width: 100%;
	height: 64px;
	background-color: #071a44;
	stroke-width: 8px;
	border: 8px;
	border-radius: 0 0 12px 12px;
	text-align: center;
}

.menu .home {
	font-size: 24px;
	width: 100%;
	color: rgb(255, 255, 255);
	text-decoration: none;
	margin: auto;
	padding-top: 16px;
	padding-bottom: 16px;
	border-radius: 0px;
	float: left;
	font-weight: bold;
}

.menu .plus {
	float: right;
	font-size: 24px;
	width: 48px;
	height: 48px;
	margin-top: 8px;
	margin-right: 16px;
	border-radius: 500px;
	border: none;
	background-color: #d3d3d3aa;
}

.canvas {
	float: right;
}

.car1, .car2 {
	justify-content: center;
	align-items: center;
	text-align: center;
	vertical-align: middle;
}

.title {
	margin: 24px 16px;
	font-size: 24px;
	text-align: center;
}

.holder {
	display: inline-flex;
	margin-bottom: 16px;
}

.value {
	font-family: 'Computer-Modern', serif;
	width: 32px;
	margin-right: 16px;
}

.input {
	font-family: 'Computer-Modern', serif;
	width: 60%;
	font-size: 16px;
}

.units {
	font-family: 'Computer-Modern', serif;
	font-size: 16px;
	width: 32px;
	margin-left: 16px;
}

#t1, #t2 {
	display: inline-block
}

.v1_angle_v {
	margin-left: 16px;
}

.degrees {
	width: 20%;
}