.page {
	font-family: 'Computer-Modern', serif;
	background-color: white;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 500px;
	height: 448px;
	border-radius: 16px;
	padding-top: 32px;
}

.menu {
	display: flex;
	justify-content: center;
	align-items: center;
}

.title {
	margin: 24px 16px;
	display: inline;
	font-size: 24px;
	width: 32px;
	text-align: center;
}

.input {
	font-family: 'Computer-Modern', serif;
	width: 60%;
	font-size: 24px;
}

.units {
	font-size: 18px;
	padding-left: 18px;
	padding-right: 18px;
	width: 32px;
}

.formula {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 16px;
	width: 132px;
	height: auto;
}