@font-face {
	font-family: 'Inter';
	src: local('Inter'), url("../../public/assets/font/Inter-Font.ttf") format('truetype');
}

@font-face {
	font-family: 'Computer-Modern';
	src: local('Computer-Modern'), url("../../public/assets/font/Computer-Modern.ttf") format('truetype');
}

.page {
	text-align: center;
}

.title {
	color: white;
	font-family: 'Inter', sans-serif;
	font-size: 24px;
}

.list {
	color: white;
}

.item {
	font-family: 'Inter', sans-serif;
	color: white;
	padding-bottom: 14px;
	text-decoration: none;
}